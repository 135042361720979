import React, { createElement } from "react";
import Storyblok, { version } from "@/lib/storyblok-client";
import { relationsToResolve } from "@/constants/general";
import Components from "@/components/components";
import { getSeoFromStory } from "@/lib/seo";
import {
  getCurrentPaths,
  isUnlisted,
  generateEvents,
} from "@/lib/storyblok-gapi";
import { useStoryblok } from "../helpers/storyblok-hook";
import { isProduction } from "../helpers/environment";
import type { Story } from "@/types/storyblok";
import type { EventStoryblok } from "@/types/component-types-sb";
import { GetStaticPropsContext } from "next";

interface Props {
  story?: Story;
  event?: EventStoryblok;
}

export default function Page({ story, event }: Props) {
  story = useStoryblok(story, !isProduction);

  return (
    story &&
    createElement(Components(story?.content?.component), {
      key: story?.uuid,
      content: story?.content,
      published: story.published_at,
      id: story?.id,
      name: story?.name,
      event: event,
    })
  );
}

export async function getStaticPaths() {
  const {
    PageItems: { items },
  } = await getCurrentPaths({ name: "PageItems" });
  const paths = items.map((item) => ({ params: { slug: item.slug } }));

  return {
    fallback: "blocking",
    paths,
  };
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const { params: { slug } = { slug: "" } } = context;

  try {
    const {
      data: { story },
    } = await Storyblok.get(
      `cdn/stories/${
        (await isUnlisted({ slug: String(slug) })) ? "unlisted/" : ""
      }${slug}`,
      {
        resolve_relations: relationsToResolve,
        version,
      }
    );

    const [event] = story?.content?.event
      ? await generateEvents({
          eventItems: story?.content?.event,
        })
      : [];

    return {
      props: {
        story,
        event: event || null,
        seo: await getSeoFromStory({ story }),
        revalidate: 86400,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      notFound: true,
    };
  }
}
